<template lang="pug">
.EvaluationPriority.Container(:style="isAtril ? 'font-size: 1.7rem;' : ''")
  .Content
    article.text-center(style="max-width: 530px; margin: auto; margin-top: 6rem;")
      p(style="margin: 0; margin-top: -3px;")
        | Si se encuentra en embarazo, es una persona
      p(style="margin: 0; margin-top: -3px;")
        | discapacitada o es adulto mayor de 62 años, le
      p(style="margin: 0; margin-top: -3px;")
        | invitamos a tomar un turno preferencial; de lo
      p(style="margin: 0; margin-top: -3px;")
        | contrario, le solicitamos abstenerse de hacerlo.
      p(style="margin: 0; margin-top: 7px;")
        | Entre todos debemos proteger, promover, respetar y
      p(style="margin: 0; margin-top: -3px;")
        | defender los derechos de esta población (Circular
      p(style="margin: 0; margin-top: -3px;")
        | única 049 de la Superintendencia Nacional de Salud).
    .d-flex.mx-auto.text-center.justify-content-around.mt-5
      b-button(variant="bluecolmedica" @click="confirmedAction" :style="colorComputed").mr-1 Aceptar
      b-button(variant="bluecolmedica" @click="goBack" :style="colorComputed") Retornar
  .Footer
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "EvaluationPriority",
  computed: {
    ...mapState({
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      isAtril: state => state.virtualrow.isAtril
    }),

    colorComputed() {
      return `
        background-color: ${this.primaryColorBlue};
        color: ${this.threeColorBlueColor};
        border-color: ${this.primaryColorBlue};
        ${this.isAtril ? "font-size: 2rem;" : ""}
      `;
    }
  },
  methods: {
    ...mapActions({
      goBack: "virtualrow/goBack",
      confirmedAction: "virtualrow/confirmedAction"
    })
  }
};
</script>

<style scoped lang="scss"></style>
