<template lang="pug">
  .Principal(:style="isAbc ? 'max-width: 750px' : ''")
    .panel(:class="isAbc ? 'abc' : ''")
      slot
</template>

<script>
export default {
  name: "Panel",

  props: {
    isAbc: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
.Principal {
  max-width: 450px;
}

.panel {
  /*box-shadow: 0px 0px 2px 1px #e2e2e2;*/
  border-radius: 5px;
  /* color: black !important; */
  display: grid;
  gap: 52px;
  font-weight: bold;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 447px;
  margin: auto;
}

.abc {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  max-width: 750px;
}

.item-panel {
  padding: 1rem;
  width: 126px;
  height: 120px;
  border-radius: 19px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 35px;
  justify-content: center;
  text-align: center;
  color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.item-panel.bigbutton {
  font-size: 35px !important;
  padding: 0.4rem 1rem;
}

.item-panel:last-of-type {
  background-color: #00599d;
  color: white;
}
/* 
.item-panel:last-of-type:hover {
  background-color: #014579;
} */
</style>
