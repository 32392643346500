<template lang="pug">
.CurrentUser.Container(:class="isAtril ? 'atril-mode' : ''" :style="isAtril && `width: 100%; overflow: hidden; max-width: 1000px; height: calc(100vh - 70px);`")
  .Content
    div.box-dk
      div(:class="isAtril ? 'd-flex justify-content-between w-100 box-container' : ''")
        div(:class="isAtril ? 'd-flex justify-content-start align-items-center flex-column p-3' : ''" :style="isAtril && 'flex: 18% !important;'")
          img(src="../../assets/images/logoAtril.png" alt="Colmedica logo" :style="isAtril ? '' : 'max-width: 180px;'").mt-4
          span.pt-4.text-center(:class="isAtril ? 'h1' : 'h1-mobile'" :style="isAtril ? 'max-width: 800px; line-height: 55px;' : 'max-width: 300px; display: block; margin: auto; text-align: center;'") {{ siteIsOffice ? "Oficina" : "Centro Médico" }} {{ siteName }}
          p.mt-3.box-red.mb-5(v-if="isAtril") {{ actualTime() }}
          p.mb-0(:class="isAtril ? 'subtitle' : 'subtitle-mobile'" :style="isAtril ? 'margin-top: 1rem; font-size: 2rem;' : 'margin-top: 10vh;'" v-if="!isAtril") Ingresa tu documento
          form#validateUser(@submit.prevent="sendData", autocomplete="off", :style="isAtril ? 'padding-top: 60px; margin: 0; max-width: 890px;' : 'padding-bottom: 50px;'")
            div(style="max-width: 690px; margin: auto; display: block;" :style="isAtril ? 'max-width: 890px;' : ''")
              p.text-start(:style="isAtril ? 'font-size: 30px; margin-bottom: 30px;' : 'font-size: 22px; color: #393939; opacity: 0.7;'") Tipo de documento
              .position-relative(:class="!isAtril ? 'box-select-atril' : 'box-select'")
                b-form-select.Input-text.text-start.mb-1(
                  v-model="models.TipoIdentificacion",
                  :options="options",
                  :style="isAtril ? 'background: none !important; background-color: rgba(0,0,0,0) !important; padding: 0px 30px !important; height: 98px !important; font-size: 30px !important; width: 734px !important; margin: auto !important; background-color: white !important; font-weight: bold !important; color: rgb(0 0 0) !important; margin-bottom: 76px !important; border-radius: 19px !important; position: relative; z-index: 5; background: none; background-color: rgba(0, 0, 0, 0) !important;' : 'font-size: 19px !important; max-width: 350px !important; margin: 18px auto !important; margin-bottom: 0 !important; z-index: 15; position: relative; background: none; background-color: rgba(0, 0, 0, 0) !important;'"
                  size="sm",
                  autocomplete="off",
                  required,
                )
                svg(width='24' height='15' viewbox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg' style="z-index:-5")
                  path(d='M2 2L12 11.5L22 2' stroke='#9E9EA0' style='stroke:#9E9EA0;stroke:color(display-p3 0.6196 0.6196 0.6275);stroke-opacity:1;' stroke-width='4' stroke-linecap='round')
              p.text-start(:style="isAtril ? 'margin-top: 0; margin-bottom: 30px; font-size: 30px;' : 'margin-top: 90px; font-size: 22px; color: #393939; opacity: 0.7;'") Número de documento
              .relative(style="position: relative;")
                input.text-start.Input-text.mt-4(
                  type="text",
                  v-model="models.NumeroIdentificacion",
                  min="0"
                  :style="isAtril ? 'padding: 30px !important; height: 98px !important; font-size: 30px !important; max-width: 734px !important; margin: 0 auto !important; background-color: white !important; font-weight: bold !important; margin-bottom: 82px !important; color: rgb(0 0 0) !important; border-radius: 19px !important; border: #055BA4 1px solid !important;' : 'font-size: 19px !important; max-width: 350px !important; margin: 18px auto !important;'"
                  required,
                  autocomplete="off",
                  :disabled="isAtril"
                )#cedula_number
                .cursor-atril(v-if="isAtril && !models.NumeroIdentificacion")
              b-button(:class="!models.TipoIdentificacion || !models.NumeroIdentificacion || charging ? 'disabled' : 'active'" variant="bluecolmedica" style="width: 401px; height: 89px;" class="text-center" type="submit" v-if="isAtril")
                span(style="font-size: 35px;" v-if="!!!charging") Continuar
                div(v-else)
                  MiniSpinner
        .d-flex.justify-content-center.align-items-center.bg-atril.px-5.py-2(v-if="isAtril")
          Panel(:isAbc="isAbc")
            .item-panel.bigbutton(@click="printPanel(1)" :style="colorComputed + 'border-color: white;'" v-if="!isAbc") 1
            .item-panel.bigbutton(@click="printPanel(2)" :style="colorComputed + 'border-color: white;'" v-if="!isAbc") 2
            .item-panel.bigbutton(@click="printPanel(3)" :style="colorComputed + 'border-color: white;'" v-if="!isAbc") 3
            .item-panel.bigbutton(@click="printPanel(4)" :style="colorComputed + 'border-color: white;'" v-if="!isAbc") 4
            .item-panel.bigbutton(@click="printPanel(5)" :style="colorComputed + 'border-color: white;'" v-if="!isAbc") 5
            //- 347px
            .item-panel.bigbutton(@click="printPanel(6)" :style="colorComputed + 'border-color: white;'" v-if="!isAbc") 6
            .item-panel.bigbutton(@click="printPanel(7)" :style="colorComputed + 'border-color: white;'" v-if="!isAbc") 7
            .item-panel.bigbutton(@click="printPanel(8)" :style="colorComputed + 'border-color: white;'" v-if="!isAbc") 8
            .item-panel.bigbutton(@click="printPanel(9)" :style="colorComputed + 'border-color: white;'" v-if="!isAbc") 9
            .item-panel.bigbutton(v-for="letter in abc" @click="printPanel(letter)" :style="colorComputed + 'border-color: white;'" v-if="isAbc") {{ letter }}
            //- .item-panel(@click="printPanel('<')" style="background: #3a8ac0; color: white;") Borrar
            .item-panel.bigbutton(@click="printPanel('<')" :style="colorComputed + 'border-color: white;' + (isAbc ? 'grid-column: 2;' : '')") Borrar
              svg(width='47' height='46' viewbox='0 0 47 46' fill='none' xmlns='http://www.w3.org/2000/svg')
                path(d='M43.0833 5.75H13.7083C12.3571 5.75 11.2996 6.42083 10.5946 7.43667L0 23L10.5946 38.5442C11.2996 39.56 12.3571 40.25 13.7083 40.25H43.0833C45.2375 40.25 47 38.525 47 36.4167V9.58333C47 7.475 45.2375 5.75 43.0833 5.75ZM43.0833 36.4167H13.8454L4.7 23L13.8258 9.58333H43.0833V36.4167ZM20.3862 32.5833L27.4167 25.7025L34.4471 32.5833L37.2083 29.8808L30.1779 23L37.2083 16.1192L34.4471 13.4167L27.4167 20.2975L20.3862 13.4167L17.625 16.1192L24.6554 23L17.625 29.8808L20.3862 32.5833Z' fill='white' style='fill:white;fill-opacity:1;')
            .item-panel.bigbutton(@click="printPanel('Z')" :style="colorComputed + 'border-color: white;'" v-if="isAbc") Z
            .item-panel.bigbutton(@click="printPanel(0)" :style="colorComputed + 'border-color: white;'" v-if="!isAbc") 0
            .item-panel.bigbutton(@click="onChangeABC" :style="colorComputed + 'border-color: white;' + (isDisabledABC ? 'opacity: 0.5' : '')") {{ isAbc ? '123' : 'ABC' }}
            //- .item-panel.bigbutton(
            //-   :class="!models.TipoIdentificacion || !models.NumeroIdentificacion || charging ? 'disabled' : 'active'"
            //-   @click="printPanel('Ok')"
            //-   :style="colorComputed + 'border-color: white;'"
            //- )
            //-   span(v-if="!!!charging") Ok
            //-   div(v-else)
            //-     MiniSpinner
  //- div(:style="colorSecondaryComputed" v-if="isAtril")
    //- #d-flex.d-flex.align-items-center(:class="!!currentUser ? 'justify-content-center' : 'justify-content-end'" v-if="!isAtril")
    //-   b-button.text-center(
    //-     v-if="!!!currentUser"
    //-     variant="bluecolmedica",
    //-     :style="colorComputed"
    //-     form="validateUser"
    //-     size="sm",
    //-     type="submit",
    //-     :disabled="!this.newUserReady || !!charging"
    //-   )
    //-     span(v-if="!!!charging")
    //-       span.text-sizing-12 Continuar
    //-       Continue(size="24px")
    //-     div(v-else)
    //-       MiniSpinner
      
    //-   b-button.text-center(
    //-     v-else
    //-     :disabled="!!charging"
    //-     variant="bluecolmedica",
    //-     size="sm",
    //-     @click="cleanCurrentUser"
    //-     type="button"
    //-     :style="colorComputed"
    //-   )
    //-     span.text-sizing-12 Continuar con otro usuario
    //-     Continue(size="24px")
    //- #d-flex.d-flex.align-items-start(v-else)
    //-   b-button.text-center(
    //-     @click="goBack",
    //-     variant="bluecolmedica",
    //-     :style="colorComputed"
    //-     size="sm",
    //-     type="submit",
    //-   )
    //-     Atras(size="24px")
    //-     span.text-sizing-12 Atrás
  .footer-button-mobile(v-if="!isAtril")
    b-button.text-center(
      variant="bluecolmedica"
      form="validateUser"
      size="sm",
      type="submit",
      :style="colorComputed"
      :disabled="!this.newUserReady || !!charging"
    )
      span.px-5(v-if="!!!charging")
        span Continuar 
      div(v-else)
        MiniSpinner
</template>

<script>
import { mapState, mapActions } from "vuex";

import Header from "./components/Header";
import Continue from "mdi-vue/ArrowRightBold";
import MiniSpinner from "../components/MiniSpinner";
import Panel from "./components/Panel";
import Atras from "mdi-vue/ArrowLeftBold";
import moment from "moment";

export default {
  name: "Home",
  components: {
    Header,
    Continue,
    MiniSpinner,
    Panel,
    Atras
  },

  created() {
    if (this.isAtril) {
      this.$store.dispatch("setTimer");
      this.cleanCurrentUser();
    }
  },

  mounted() {
    this.$store.dispatch("turns/cancelTurnAction", true);
    document.body.style.backgroundImage = "";
  },

  data: () => ({
    myForm: "",
    captchaElement: null,
    isCapcha: true,
    isAbc: false,
    isDisabledABC: true,
    abc: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y"
    ],
    models: {
      NumeroIdentificacion: "",
      TipoIdentificacion: "CC"
    }
  }),

  watch: {
    "models.TipoIdentificacion"(val) {
      if (val) {
        this.isDisabledABC = val === "CC";
        if (this.isAtril) {
          this.$store.dispatch("setTimer");
        }
      }
    }
  },

  computed: {
    ...mapState({
      currentSite: state => state.virtualrow.currentSite,
      options: state => state.virtualrow.tipide,
      currentUser: state => state.virtualrow.currentUser,
      citas: state => state.virtualrow.citas,
      charging: state => state.virtualrow.charging,
      isAtril: state => state.virtualrow.isAtril,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      secondColorBlue: state => state.virtualrow.secondColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      error: state => state.virtualrow.error
    }),

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    toSend() {
      return { ...this.models, CodigoOficina: this.$route.params.code };
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    newUserReady() {
      return !!(
        this.models.NumeroIdentificacion && this.models.TipoIdentificacion
      );
    },

    siteName() {
      return this.currentSite?.name;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    }
  },

  methods: {
    ...mapActions({
      goToView: "virtualrow/goToView",
      goBack: "virtualrow/goBack",
      authenticate: "virtualrow/authenticate",
      cleanCurrentUser: "virtualrow/cleanCurrentUser",
      removeTimer: "removeTimer",
      setTimer: "setTimer",
      sendTracking: "virtualrow/handleTracking"
    }),
    actualTime() {
      const timer = setTimeout(() => {
        this.actualTime();
        clearTimeout(timer);
      }, 1000);
      let n = moment()
        .locale("es")
        .format("dddd DD [de] MMMM[,] hh:mm a")
        .replace("am", "a.m.")
        .replace("pm", "p.m.");
      return n[0].toUpperCase() + n.substring(1);
    },
    continueWithExistent() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.models.TipoIdentificacion = this.currentUser.TipoIdentificacion;
      this.models.NumeroIdentificacion = this.currentUser.NumeroIdentificacion;
      this.sendData();
    },
    onChangeABC() {
      this.isAbc = !this.isAbc;
    },
    printPanel(key) {
      if (this.isAtril) {
        this.$store.dispatch("setTimer");
      }
      if (key === "<") {
        this.models.NumeroIdentificacion = this.models.NumeroIdentificacion.substring(
          0,
          this.models.NumeroIdentificacion.length - 1
        );
        return;
      }
      if (key == "Ok") {
        if (
          !this.models.NumeroIdentificacion ||
          !this.models.TipoIdentificacion
        )
          return;
        return this.sendData();
      }
      this.models.NumeroIdentificacion += key;
    },
    async sendData() {
      document.getElementById("cedula_number")?.blur();
      if (this.isAtril) await this.removeTimer();
      await this.authenticate(this.toSend);
      if (this.isAtril) await this.setTimer();

      // tracking
      setTimeout(() => {
        let origin = "fv_";
        if (location.hash.includes("fv_atril")) origin = "fv_atril_";
        let auth = this.error
          ? " autentitacion-no-cliente"
          : "autenticacion-cliente";
        let trackingData = {
          uuid: window.person.id,
          accion: auth,
          debmedia_turn_code: "",
          url_origen: window.location.href,
          origen: origin + this.currentSite?.type,
          mensajes: ""
        };

        this.sendTracking(trackingData);
      }, 2000);
    }
  }
};
</script>

<style scoped>
.CurrentUser {
  width: 100%;
  height: calc(100vh - 152px);
  overflow: auto;
  display: table;
  table-layout: fixed;
  position: relative;
}

.CurrentUser > div {
  max-height: calc(100vh - 173px);
  display: table-cell;
  -ms-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  -o-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  text-overflow: ellipsis;
  vertical-align: middle;
}

.box-dk {
  margin: auto;
  text-align: center;
  line-height: 1;
  font-size: 1.3rem;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  margin: auto;
  z-index: 12300000;
  padding: 1rem;
}

form {
  margin: auto;
  margin-top: 50px;
  max-width: 491px;
}

.input,
#input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 16px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}

.button {
  background-color: #0057a0;
  /* color: #fff; */
  font-size: 17px;
  margin: 30px auto;
  border-radius: 9px;
  padding: 5px;
  width: 70%;
  border: none;
  display: block;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

#d-flex span svg {
  width: 20px;
  height: 20px;
  /* fill: white; */
}

.big_box {
  position: relative;
}

.dk-link {
  outline: none;
  border: none;
  appearance: none;
  text-decoration: none;
  background: none;
  /* color: #6565; */
  color: #6558f5;
  display: block;
  text-align: center;
  font-size: 1.2rem;
  margin: 93px auto;
}

.dk-link:hover {
  text-decoration: underline;
}

.pos_dk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 30;
}

.disabled {
  cursor: not-allowed;
  background: #0058a0a4;
}

.box-red {
  color: #055ba4;
  font-size: 25px;
  font-weight: 400;
  padding-left: 10px;
  border-left: 2px solid red;
  max-width: 1280px;
  margin: 0 auto !important;
  margin-bottom: 1.25rem !important;
  display: block;
}

.disabled:hover {
  background: #0058a0cb;
}

.dk-pos-dk {
  position: absolute;
  top: 30%;
  left: 0px;
  font-weight: bold;
  transition: 1s;
  right: 0px;
}

.Input-text::placeholder {
  color: rgb(0 0 0) !important;
}

.h1 {
  font-size: 55px;
  color: var(--color-primary);
  font-weight: 900;
}

.h1-mobile {
  font-size: 30px;
  color: var(--color-primary);
  font-weight: bold;
}

.subtitle-mobile {
  font-size: 24px;
  text-align: start;
  color: var(--color-primary);
  font-weight: 900;
}

.text-start {
  text-align: start;
}

.footer-button-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: none;
  left: 0;
  z-index: 30;
  padding: 10px;
  padding-bottom: 33px;
}

.atril-mode {
  position: fixed;
  inset: 0;
  z-index: 30;
  background: #fff;
  overflow: auto;
  max-width: 100% !important;
  height: 100vh !important;
  width: 100vw !important;
  justify-content: stretch !important;
  align-items: stretch !important;
}

.atril-mode .Content,
.atril-mode .box-dk {
  flex: 1;
  max-height: none;
  height: 100%;
}

.box-container {
  flex: 1;
  height: 100%;
}

.box-container > div {
  flex: 1;
}

.bg-atril {
  background-image: url(../../assets/images/bg-atril.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  background-color: #fff;
}

.box-select {
  position: relative;
}

.box-select svg,
.box-select-atril svg {
  position: absolute;
  right: 15px;
  bottom: 38px;
  background: white !important;
}

.box-select-atril svg {
  bottom: 10px;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}

.cursor-atril {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
  height: 35px;
  width: 4px;
  background-color: rgb(32, 32, 32);
  transition: 1s all;
  animation: infinite 0.5s cursorFade ease-in-out;
}

@keyframes cursorFade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
