<template lang="pug">
.Home.Container.p-1(style="color: #545454; max-width: 1012px; margin: auto; width: 100%;")
  .Content
    .d-flex(
      style="flex-direction: column; align-items: center; justify-content: center; height: 100%;"
    )
      article(style="width: 100%;")
        div(style="position: relative; height: 66px; font-weight: bold; margin-top: 60px; font-size: 2.5rem;")
          span.text-center.mt-2.h1.dk-pos-dk {{ siteIsOffice ? "Oficina" : siteName.toLowerCase().includes("centro") ? "" : "Centro Médico" }} {{ siteName }}
        p.text-center(style="margin-bottom: 50px; font-size: 2rem;").mt-3 Para tomar un turno, escanea este código QR con tu celular.
        .canvas_container
          #qr
        p.text-center(style="margin-top: 80px; margin-bottom: 10px; font-size: 2rem;") O presiona continuar para tomar un turno impreso
        b-button(
          variant="bluecolmedica",
          :style="'display: block; margin: auto; font-size: 2rem;' + colorComputed",
          @click="goCurrent"
        ) Continuar
  .Footer
</template>

<script>
import QrCode from "easyqrcodejs";
import { mapState } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      qrcode: null,
      text: "",
      changeColor: "",
      changeColorText: "",
      changeColorThree: ""
    };
  },
  mounted() {
    this.$store.dispatch("turns/cancelTurnAction", true);
    this.text = `${location.origin}/#/fv/${this.$route.params.code}${
      this.$route.query.area ? "?area=" + this.$route.query.area : ""
    }`;
    // eslint-disable-next-line no-unused-vars
    this.qrcode = new QrCode(document.getElementById("qr"), {
      text: this.text,
      width: 150,
      height: 150,
      colorDark: this.primaryColorBlue || "#00599d",
      colorLight: "#FFFFFF"
    });
  },

  watch: {
    primaryColorBlue(val) {
      document
        .getElementById("qr")
        .querySelector("canvas")
        ?.remove();
      this.qrcode = new QrCode(document.getElementById("qr"), {
        text: this.text,
        width: 150,
        height: 150,
        colorDark: `${val}` || "#00599d",
        colorLight: "#FFFFFF"
      });
    }
  },

  computed: {
    ...mapState({
      currentSite: state => state.virtualrow.currentSite,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor
    }),

    siteName() {
      return this.currentSite?.name;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    }
  },

  methods: {
    goCurrent() {
      this.$store.dispatch("virtualrow/goToView", {
        view: "Home"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.Home {
  height: calc(100vh - 70px);
  overflow: auto;
  display: table;
}

.Home > div {
  display: table-cell;
  vertical-align: middle;
}

.dk-pos {
  position: fixed;
  top: 0;
  right: 0;
}

.canvas_container {
  margin: 0px auto;
  display: flex;
  max-width: 170px;
  justify-content: center;
  #canvas {
    width: 170px !important;
    height: 170px !important;
    object-fit: contain;
  }
}

.dk-pos-dk {
  position: absolute;
  top: -90%;
  left: 0px;
  transition: 1s;
  right: 0px;
  font-weight: bold;
  font-size: 2.8rem;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}
</style>
